import React from "react";
import PropTypes from "prop-types";

import Logo from "components/Logo";
import "./ConsortiumItem.scss";

const ConsortiumItem = ({ imageFileName, imageURL, header, content }) => {

  let imagePart;
  if (imageFileName) {
    imagePart = <Logo className="consortium-item-image" fileName={imageFileName} />;
  }

  return (
    <>
      <a href={imageURL} target="_blank" rel="noreferrer">
        {imagePart}
        <h4 className="consortium-item-heading">{header}</h4>
        <p>{content}</p>
      </a>
    </>
  );
};

ConsortiumItem.propTypes = {
  imageURL: PropTypes.string,
  imageFileName: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string,
};

ConsortiumItem.defaultProps = {
  imageURL: null,
  imageFileName: null,
  header: "",
  content: "",
};

export default ConsortiumItem;
