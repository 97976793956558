import React from "react";
import PropTypes from "prop-types";

import { Row } from "react-bootstrap";

import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

const Services = ({ className, frontmatter }) => {
    if (!frontmatter) {
        return null;
    }

    const { anchor, header: rootHeader, subheader: rootSubHeader, content: rootContent } = frontmatter;

    return (
        <PageSection className={className} id={anchor}>
            <Row>
                <SectionHeader header={rootHeader} subheader={rootSubHeader} content={rootContent} />
            </Row>
        </PageSection>
    );
};

Services.propTypes = {
    className: PropTypes.string,
    frontmatter: PropTypes.object,
};

Services.defaultProps = {
    className: null,
    frontmatter: null,
};

export default Services;
