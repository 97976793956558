import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";

import ConsortiumItem from "components/ConsortiumItem";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

const Consortium = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, content: rootContent, consortium, contentSecondary } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} content={rootContent} />
      </Row>
      <Row className="text-center consortium-items">
        {consortium.map((service) => (
          <Col md={2} key={service.header}>
            <ConsortiumItem {...service} />
          </Col>
        ))}

      </Row>
      <Row>
        <SectionHeader content={contentSecondary} />
      </Row>
    </PageSection>
  );
};

Consortium.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Consortium.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Consortium;
