import makeFAIcon from "utils/makeFAIcon";

import { faShoppingCart, faLaptop, faLock, faStreetView, faHandsHelping, faProjectDiagram } from "@fortawesome/free-solid-svg-icons";

export const ShoppingCartIcon = makeFAIcon(faShoppingCart);
export const LaptopIcon = makeFAIcon(faLaptop);
export const LockIcon = makeFAIcon(faLock);
export const StreetViewIcon = makeFAIcon(faStreetView);
export const HandsHelpingIcon = makeFAIcon(faHandsHelping);
export const ProjectDiagramIcon = makeFAIcon(faProjectDiagram);
