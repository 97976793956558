import React from "react";
import PropTypes from "prop-types";

import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Logo = ({ fileName, alt, ...restProps }) => (
    <StaticQuery
        query={graphql`
      query LogoQuery {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                sizes(maxWidth: 1920, quality:90) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
      }
    `}
        render={(data) => {
            const image = data.images.edges.find((n) => n.node.relativePath.includes(fileName));

            if (!image) {
                return null;
            }

            const imageSizes = image.node.childImageSharp.sizes;
            return <Img alt={alt} sizes={imageSizes} {...restProps} />;
        }}
    />
);

Logo.propTypes = {
    fileName: PropTypes.string.isRequired,
    alt: PropTypes.string,
};

Logo.defaultProps = {
    alt: null,
};

export default Logo;
